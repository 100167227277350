import { cn } from "@holdenmatt/shadcn-kit";

type Props = {
  children: React.ReactNode;
  size?: "sm" | "md" | "lg";
  className?: string;
};

export const Container = ({ children, size = "md", className }: Props) => (
  <div
    className={cn(
      "mx-auto w-full px-4 sm:px-6 lg:px-8",
      {
        "max-w-3xl": size === "sm",
        "max-w-4xl": size === "md",
        "max-w-7xl": size === "lg",
      },
      className
    )}
  >
    {children}
  </div>
);
